var countNotifications = 0;

!function ($) {
    "use strict";

    var Components = function () { };

    //initializing tooltip
    Components.prototype.initTooltipPlugin = function () {
        $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()
    },

    //initializing popover
    Components.prototype.initPopoverPlugin = function () {
        $.fn.popover && $('[data-toggle="popover"]').popover()
    },

    //initializing Slimscroll
    Components.prototype.initSlimScrollPlugin = function () {
        //You can change the color of scroll bar here
        $.fn.slimScroll && $(".slimscroll").slimScroll({
            height: 'auto',
            position: 'right',
            size: "4px",
            touchScrollStep: 20,
            color: '#9ea5ab'
        });
    },

    //initializing form validation
    Components.prototype.initFormValidation = function () {
        $(".needs-validation").on('submit', function (event) {
            $(this).addClass('was-validated');
            if ($(this)[0].checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            return true;
        });
    },

    //initilizing
    Components.prototype.init = function () {
        var $this = this;
        this.initTooltipPlugin(),
        this.initPopoverPlugin(),
        this.initSlimScrollPlugin(),
        this.initFormValidation()
    },

    $.Components = new Components, $.Components.Constructor = Components

}(window.jQuery),


function ($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    /**
    Resets the scroll
    */
    App.prototype._resetSidebarScroll = function () {
        // sidebar - scroll container
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "4px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 20
        });
    },

    /**
     * Initlizes the menu - top and sidebar
    */
    App.prototype.initMenu = function () {
        var $this = this;

        // Left menu collapse
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();

            var layout = $this.$body.data('layout');
            if (layout === 'topnav') {
                $(this).toggleClass('open');
                $('#topnav-menu-content').slideToggle(400);
            } else {
                $this.$body.toggleClass('sidebar-enable');
                if ($this.$window.width() >= 768) {
                    $this.$body.toggleClass('left-side-menu-condensed');
                } else {
                    $this.$body.removeClass('left-side-menu-condensed');
                }

                // sidebar - scroll container
                $this._resetSidebarScroll();
            }
        });

        // sidebar - main menu
        // activate the menu in left side bar based on url
        var layout = $this.$body.data('layout');
        if ($('#menu-bar').length) {
            if (layout !== 'topnav') {
                // init menu
                new MetisMenu('#menu-bar');
                // sidebar - scroll container
                $this._resetSidebarScroll();

                $("#menu-bar a").each(function () {
                    var pageUrl = window.location.href.split(/[?#]/)[0];
                    if (this.href == pageUrl) {
                        $(this).addClass("active");
                        $(this).parent().addClass("mm-active"); // add active to li of the current link
                        $(this).parent().parent().addClass("mm-show");
                        $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                        $(this).parent().parent().parent().addClass("mm-active");
                        $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
                        $(this).parent().parent().parent().parent().parent().addClass("mm-active");
                    }
                });

            } else {
                var menuRef = new MetisMenu('#menu-bar').on('shown.metisMenu', function (event) {
                    window.addEventListener('click', function menuClick(e) {
                        if (!event.target.contains(e.target)) {
                            menuRef.hide(event.detail.shownElement);
                            window.removeEventListener('click', menuClick);
                        }
                    });
                });
                $("#menu-bar a").each(function () {
                    var pageUrl = window.location.href.split(/[?#]/)[0];
                    if (this.href == pageUrl) {
                        $(this).addClass("active");
                        $(this).parent().addClass("active"); // add active to li of the current link
                        $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                        $(this).parent().parent().parent().addClass("active");
                        $(this).parent().parent().parent().parent().parent().addClass("active");
                    }
                });
            }
        }

        // right side-bar toggle
        $('.right-bar-toggle').on('click', function (e) {
            $('body').toggleClass('right-bar-enabled');
        });

        $(document).on('click', 'body', function (e) {
            if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                return;
            }

            if ($(e.target).closest('.left-side-menu, .side-nav').length > 0 || $(e.target).hasClass('button-menu-mobile')
                || $(e.target).closest('.button-menu-mobile').length > 0) {
                return;
            }

            $('body').removeClass('right-bar-enabled');
            $('body').removeClass('sidebar-enable');
            return;
        });


        // activate topnav menu
        // $('#topnav-menu li a').each(function () {
        //     var pageUrl = window.location.href.split(/[?#]/)[0];
        //     if (this.href == pageUrl) {
        //         $(this).addClass('active');
        //         $(this).parent().parent().addClass('active'); // add active to li of the current link
        //         $(this).parent().parent().parent().parent().addClass('active');
        //         $(this).parent().parent().parent().parent().parent().parent().addClass('active');
        //     }
        // });

        // // horizontal menu
        // $('#topnav-menu .dropdown-menu a.dropdown-toggle').on('click', function () {
        //     console.log("hello");
        //     if (
        //         !$(this)
        //             .next()
        //             .hasClass('show')
        //     ) {
        //         $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
        //     }
        //     var $subMenu = $(this).next('.dropdown-menu');
        //     $subMenu.toggleClass('show');
        //
        //     return false;
        // });

        // Preloader
        $(window).on('load', function () {
            $('#status').fadeOut();
            $('#preloader').delay(350).fadeOut('slow');
        });

        $('.listCheckboxes').each(function () {
            $(this).attr("checked", false);
        });

        function getCheckedElements() {
            var elements = [];

            $('.listCheckboxes').each(function(){
                if($(this).is(':checked')) {
                    elements.push($(this).val());
                }
            });

            return elements;
        }

        $this.$body.on('click', '.move-to-archive', function() {
            var ids = getCheckedElements();

            ids.forEach(function (id){
                $.ajax({
                    method: "POST",
                    url: Routing.generate('inquiry_archive', {'id': id}),
                    dataType: 'json',
                    data: {
                        entityId: id
                    }
                })
                .done(function (jsonResponse) {
                    if (jsonResponse.status == 'OK') {
                        $('#messagePopupOkLabel').html(Translator.trans('inquiry.archived'));
                        $('#messagePopupOkMessage').html(Translator.trans('inquiry.messages.archived'));

                        $('#messagePopupOk').modal('show');

                        $('[entity-id='+ id +']').remove();
                    } else {
                        $('#messagePopupErrorLabel').html(Translator.trans('common.error'));
                        var message = Translator.trans('inquiry.messages.inquiry_could_not_be_archived');
                        if ((jsonResponse.message !== undefined) && (jsonResponse.message != '')) {
                            message = message + ' ' + Translator.trans('common.messages.error_message') + ' : ' + jsonResponse.message;
                        }
                        $('#messagePopupErrorMessage').html(message);
                        $('#messagePopupError').modal('show');
                    }
                });
            });

            ids = [];
        });
        //
        // $this.$body.on('click', '.menu-toggle', function () {
        //     var $dropMenu = $this.$body.find('.dropdown-menu.dropdown-menu-right');
        //
        //     if(!$dropMenu.hasClass('show')){
        //         $dropMenu.addClass('show');
        //     } else {
        //         $dropMenu.removeClass('show');
        //     }
        // });
    },

    /**
     * Init the layout - with broad sidebar or compact side bar
    */
    App.prototype.initLayout = function () {
        // in case of small size, add class enlarge to have minimal menu
        if (this.$window.width() >= 768 && this.$window.width() <= 1024) {
            this.$body.addClass('left-side-menu-condensed');
        } else {
            if (this.$body.data('left-keep-condensed') != true) {
                this.$body.removeClass('left-side-menu-condensed');
            }
        }

        // if the layout is scrollable - let's remove the slimscroll class from menu
        if (this.$body.hasClass('scrollable-layout')) {
            $('#sidebar-menu').removeClass("slimscroll-menu");
        }
    },

    //initilizing
    App.prototype.init = function () {
        var $this = this;
        this.initLayout();
        this.initMenu();
        $.Components.init();
        // on window resize, make menu flipped automatically
        $this.$window.on('resize', function (e) {
            e.preventDefault();
            $this.initLayout();
            $this._resetSidebarScroll();
        });

        // feather
        feather.replace();
    },

    $.App = new App, $.App.Constructor = App


}(window.jQuery),
//initializing main application module
function ($) {
    "use strict";
    $.App.init();

    if ($('#inquiry-discussion-entries').length) {
        var parent = $('#inquiry-discussion-entries');
        setInterval(function(){
            $.ajax({
                method: "POST",
                url: Routing.generate('refresh_inquiry_discussion_entry'),
                dataType: 'json',
                data: {
                    entityId: parent.data('entity-id')
                }
            })
            .done(function( jsonResponse ) {
                $('#entries').html(jsonResponse.html);
            });
        }, 20000);
    }

    $('#searcher-phrase').on('keyup', function (e) {
        if(e.keyCode === 13) {
            $("#loader-wrapper-searcher").show();
            window.location.href='/searcher/' + btoa(unescape(encodeURIComponent($('#searcher-phrase').val())));//btoa($('#searcher-phrase').val());
            return;

            //reszta nieistotna, ale na razie niech zostanie
            var searchPhrase = $('#searcher-phrase').val();
            var lp = 1;

            $.ajax({
                url: '/searcher',
                method: 'POST',
                data: {
                    searchParam: searchPhrase
                }
            }).done(function (res) {
                $('.message-list').html(res.html);
                $('[data-toggle="tooltip"]').tooltip();
                $('span[data-toggle="tooltip"]').tooltip();
                $.App.init();
            });
        }
    });

    $('.button-set-as-default').on('click', function(e) {
        var defaultAddressId = $(this).data('address-id');

        $.ajax({
            url: '/set-default-address',
            method: 'POST',
            data: {
                addressId: defaultAddressId
            }
        }).done(function (jsonResponse) {
            if (jsonResponse.status === 'OK') {
                $('.span_id_address_button').each(function() {
                    $(this).show();
                });

                $('.span_id_address_label').each(function() {
                    $(this).hide();
                });

                console.log('#span_id_address_button_' + jsonResponse.addressId);

                $('#span_id_address_button_' + jsonResponse.addressId).hide();
                $('#span_id_address_label_' + jsonResponse.addressId).show();
            } else {
                alert(jsonResponse.message);
            }
        });
    });

    $('.button-set-contact-person-as-default').on('click', function(e) {
        var defaultContactPersonId = $(this).data('contact-person-id');

        $.ajax({
            url: '/set-default-contact-person',
            method: 'POST',
            data: {
                contactPersonId: defaultContactPersonId
            }
        }).done(function (jsonResponse) {
            if (jsonResponse.status === 'OK') {
                $('.span_id_contact_person_button').each(function() {
                    $(this).show();
                });

                $('.span_id_contact_person_label').each(function() {
                    $(this).hide();
                });

                $('.span_id_contact_person_activity').each(function() {
                    $(this).show();
                });

                $('#span_id_contact_person_button_' + jsonResponse.contactPersonId).hide();
                $('#span_id_contact_person_label_' + jsonResponse.contactPersonId).show();
                $('#span_id_contact_person_activity_' + jsonResponse.contactPersonId).hide();
            } else {
                alert(jsonResponse.message);
            }
        });
    });

    if($('body').find('.choose-make-select').length > 0) {
        if($('.choose-make-select').val() != "") {
            var makeId = $('.choose-make-select').val();

            if(makeId === "") {
                return false;
            }

            getSuppliersForMake(makeId);
        }
    }

    $('.choose-make-select').on('change', function() {

        var makeId = $(this).val();

        if(makeId === "") {
            return false;
        }

        getSuppliersForMake(makeId);
    });

    $('body').on('click', '#inquiry_submit', function (e) {
        e.preventDefault();

        var supplierCount = 0;
        $('input.inquiry_choosedSuppliers').each(function(index, checkbox) {
            if($(checkbox).is(':checked:enabled')) {
                supplierCount = supplierCount + 1;
            }
        });

        if(supplierCount >= 1 && supplierCount <= 3) {
            $('form').submit();
        }

        if (supplierCount >= 4) {
            $(window).scrollTop('#error-div');
            $('#error-div').html('<div class="alert alert-danger">Uwaga! Możesz wybrać maksymalnie 3 dostawców danej marki do których wyślesz swoje zapytanie.</div>').show();
        }

        if(supplierCount === 0) {
            $(window).scrollTop('#error-div');
            $('#error-div').html('<div class="alert alert-danger">Wybierz dostawców do których chcesz wysłać zapytanie.</div>').show();
        }
    })
    ;

    function getSuppliersForMake(makeId) {

        $.ajax({
            url: '/get-suppliers-for-make',
            method: 'POST',
            data: {
                makeId: makeId
            }
        }).done(function (jsonResponse) {

            $('#inquiry_choosedSuppliers .form-check').hide();
            $('#inquiry_choosedSuppliers .supplier-row').hide();

            $('#supplier-warning-your-make').remove();

            if (jsonResponse.status === 'OK') {

                $('#inquiry_choosedSuppliers').find('input[type="checkbox"]').each(function (index, checkbox) {
                    $(checkbox).prop('checked', false);
                });

                $.each(jsonResponse.suppliers, function (k, v) {
                    $('#rowSupplier-' + v).show();
                    $('.inquiry_choosedSuppliers_' + v).prop('checked', false);
                    $('.inquiry_choosedSuppliers_' + v).parent().show();

                    if (typeof checkedSuppliersIds !== 'undefined') {
                        $.each(checkedSuppliersIds, function (k2, v2) {
                            if (v === v2) {
                                $('.inquiry_choosedSuppliers_' + v2).prop('checked', true);
                            }
                        });
                    }
                });

                //po zmianie marki reset dostawców dla zapytania utworzonego dla innego zapytania
                checkedSuppliersIds = [];
            } else if (jsonResponse.status === 'CANT_SEE_SUPPLIERS') {
                $('#inquiry_choosedSuppliers').after('<div id="supplier-warning-your-make"><strong>Ty jesteś dostawcą tej marki, nie możesz złożyć zapytania na swoją markę.</strong></div> ')
            }

            $('#choosed-suppliers-div').removeClass('d-none');
        });
    }

    function notificationModule() {
        $.ajax({
            url: Routing.generate('notification_get'),
            method: 'GET',
            success: function (notifications) {
                $('#notifications-list').html(notifications.html);

                if(typeof notifications.countNotifications != 'undefined') {
                    if(notifications.countNotifications === 0) {
                        $('.notification-list .noti-icon-badge').hide();
                    }else {
                        if (!window.Notification) {
                            console.log('Przeglądarka nie wspiera powiadomień PUSH.');
                        } else {
                            var message = '[Cześci dla serwisu] Masz '+ notifications.countNotifications +' nieprzeczytane powiadomienia.';

                            if(notifications.countNotifications !== countNotifications){
                                if (Notification.permission === 'granted') {
                                    new Notification(message);
                                    countNotifications = notifications.countNotifications;
                                } else {
                                    Notification.requestPermission().then(function(p) {
                                        if(p === 'granted') {
                                            new Notification(message);
                                            countNotifications = notifications.countNotifications;
                                        } else {
                                            console.log('Powiadomienia są wyłączone.');
                                        }
                                    }).catch(function(err) {
                                        // console.error(err);
                                    });
                                }
                            }

                        }
                        $('#notifications-list').css('height', '');
                        $('#notifications-list').css('min-height', '420px');
                        $('.notification-list .noti-icon-badge').show();
                        $('#notificationsCount').html(notifications.countNotifications);
                    }
                }

            }
        }).done(function (){
            $('#notifications-list').slimScroll();
            executeNotificationModuleFunctions();
        });
    }

    function executeNotificationModuleFunctions() {
        $('#wrapper .notification-list .read-notification').on('click', function (e) {
            e.preventDefault();

            $.ajax({
                url: $(this).attr('data-action'),
                method: 'POST',
                success: function (res) {
                    if(res.status === 'OK') {
                        $('.notify-item[data-notification-id='+ res.id + ']').remove();
                    }
                }
            });
        });

        $('#read-all-notification').on('click', function (e) {
            e.preventDefault();

            $.ajax({
                url: $(this).attr('data-action'),
                method: 'POST',
                success: function (res) {
                    if(res.status === 'OK') {
                        console.log('OK');
                        notificationModule();
                    }
                }
            });
        });

    }

    notificationModule();

    function getMessagesForPrivateMessages() {
        $.ajax({
            url: Routing.generate('private_message_get_messages', {'id': $('#offerId').val()}),
            method: 'GET',
            success: function (response) {
                $('#comments').html(response.html);
            }
        });
    }

    setInterval(notificationModule, 30000);
    if(typeof $('#offerId').val() != 'undefined') {
        setInterval(getMessagesForPrivateMessages, 60000)
    }

    $("#partsReceived, #partsIssued").on('change', function() {
        var data = {
            'partsReceived': $('#partsReceived').is(':checked'),
            'partsIssued': $('#partsIssued').is(':checked')
        };

        $.ajax({
            url: Routing.generate('inquiry_part_status', {id: $(this).attr('data-id')}),
            method: 'POST',
            data: data,
            success: function (res) {
                alert(res.message);
            },
            error: function (e) {
                console.log({e: e});
            }
        })
    });

    $('.disableEnter').on('keydown', function(event) {
        if(event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    })

    $('body')
        .on('click', '.send-offer-reminder', function (e) {
            var sender = $(this);
            $.ajax({
                url: Routing.generate('inquiry_send_reminder_to_supplier'),
                method: 'POST',
                data: {
                    inquiryId: sender.data('inquiry-id'),
                    supplierId: sender.data('supplier-id'),
                    inquirySupplierId: sender.data('inquiry-supplier-id')
                }
            }).done(function( jsonResponse ) {
                if (jsonResponse.status === 'OK') {
                    $('#send-offer-reminder-for-' + sender.data('supplier-id')).remove();
                    alert('Przypomnienie zostało wysłane do dostawcy.')
                } else {
                    alert('Nie udało się wysłać przypomnienia do dostawcy, skontaktuj się z administratorem.');
                }
            });
        })
        .on('change', '.changeCustomerAddress', function() {
            var sender = $(this);
            $.ajax({
                url: Routing.generate('inquiry_update_supplier_addresses_routes'),
                method: 'POST',
                data: {
                    addressId: sender.val()
                }
            }).done(function( jsonResponse ) {
                $.each(jsonResponse, function (index, template) {
                    $('#route-button-for-supplier-' + index).html(template);
                })
            });
        }).on('click', '#privateMessages #comments .read-notification', function() {
            //e.preventDefault();

            $.ajax({
                url: $(this).attr('data-action'),
                method: 'POST',
                success: function (res) {
                    if(res.status === 'OK') {
                        $('.notify-item[data-notification-id='+ res.id + ']').remove();
                        $('#privateMessages #comments .read-notification-' + res.id).remove();
                        getMessagesForPrivateMessages();
                    }
                }
            });
        }).on('click', '#privateMessages #comments .unread-notification', function() {
            //e.preventDefault();

            $.ajax({
                url: $(this).attr('data-action'),
                method: 'POST',
                success: function (res) {
                    if(res.status === 'OK') {
                        $('.notify-item[data-notification-id='+ res.id + ']').remove();
                        $('#privateMessages #comments .unread-notification-' + res.id).remove();
                        getMessagesForPrivateMessages();
                    }
                }
            });
        }).on('click', '.show-suppliers-announcement', function() {
            $.ajax({
                method: "POST",
                url: Routing.generate('announcement_show_popup'),
                dataType: 'json',
                data: {
                    type: 'supplier'
                }
            })
            .done(function( jsonResponse ) {
                if (jsonResponse.status === 'OK') {
                    $('#fullScreenPopupLabel').html('Komunikat dla dostawców');
                    $('#fullScreenPopupMessage').html(jsonResponse.content);
                    $('#fullScreenPopup').modal('show');
                }
            });
        }).on('click', '.show-recipients-announcement', function() {
            $.ajax({
                method: "POST",
                url: Routing.generate('announcement_show_popup'),
                dataType: 'json',
                data: {
                    type: 'recipient'
                }
            })
            .done(function( jsonResponse ) {
                if (jsonResponse.status === 'OK') {
                    $('#fullScreenPopupLabel').html('Komunikat dla odbiorców');
                    $('#fullScreenPopupMessage').html(jsonResponse.content);
                    $('#fullScreenPopup').modal('show');
                }
            });
        }).on('click', '.add-time-to-inquiry', function() {
            let sender = $(this);
            if (confirm('Czy na pewno chcesz dodać 3 godziny do czasu zapytania ofertowego?')) {
                $.ajax({
                    method: "POST",
                    url: Routing.generate('inquiry_add_time_to_inquiry'),
                    dataType: 'json',
                    data: {
                        inquiryId: sender.attr('data-inquiry-id')
                    }
                })
                .done(function( jsonResponse ) {
                    if (jsonResponse.status === 'OK') {
                        $('#messagePopupOkLabel').html('Czas został zwiększony');
                        $('#messagePopupOkMessage').html('Dziękujemy.  Otrzymujesz dodatkowe 3 godziny czasu na odpowiedź do tego zapytania<br/>Uwaga: Staraj się składać swoje oferty zawsze jak najszybciej, dzieki temu zwiększasz swoje szanse na sprzedaż części, ponieważ Serwis Kupujący może zawsze zakończyć swoje zapytanie przed czasem');
                        $('#messagePopupOkButton').unbind().click($('#messagePopupOk').modal('hide'));
                        $('#messagePopupOk').modal('show');
                    } else {
                        alert('Nie udało się dodać czasu do zapytania ofertowego, skontaktuj się z administratorem');
                    }
                });
            }
        })
        .on('click', '.show-pdf-file-view', function() {
            showPdfFileView($(this));
            return false;
        })
    ;

    function showPdfFileView(button)
    {
        $.ajax({
            url: Routing.generate('inquiry_get_pdf_view'),
            type: 'POST',
            data: {
                entityName: button.attr('data-entity-name'),
                entityId: button.attr('data-entity-id'),
                fileId:  button.attr('data-file-id'),
            }
        }).done(function (jsonResponse) {
            if (jsonResponse.status === 'OK') {
                $('#fullScreenPopupLabel').html('Podgląd pdf');
                $('#fullScreenPopupMessage').html(jsonResponse.html);
                $('#fullScreenPopup').modal('show');
            } else {
                alert('Nie udało się pokazać podglądu pliku pdf, skontaktuj się z administratorem');
            }
        });
    }

}(window.jQuery);
